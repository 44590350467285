import { Spin, Row, Col, Table, Modal, Input, notification,Button,Drawer } from "antd";
import { useState, useEffect } from "react";
import requireAuth from "../../hocs/requireAuth";
import {
  getCommentsList,
  deleteComment,
  getComment,
} from "../../redux/actions/superAdminAction";
import get from "lodash/get";
import { DELETED_COMMENT_SUCCESS } from "../../constants/messages";
import { CloseOutlined } from '@ant-design/icons';
import ViewContactDetails from './ViewContactDetails';
import "./style.scss";
const { Search } = Input;

const CommentsScreen = () => {
  const [loading, setLoading] = useState(false);
  const [showViewDrawer, setShowViewDrawer] = useState(false);
  const [data, setData] = useState(null);
  const [filter, setFilter] = useState({
    page: 1,
    query: "",
  });
  const [commentsResult, setCommentsResult] = useState({
    comments: [],
    pagination: null,
  });

  const fetchComments = (page, query = "") => {
    setLoading(true);
    getCommentsList(page, query)
      .then((result) => {
        setLoading(false);
        setCommentsResult({
          comments: get(result, "data"),
          pagination: get(result, "meta.pagination"),
        });
        setFilter({ page });
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchComments(get(filter, "page"), get(filter, "query"));
  }, []);
  const removeComment = (id) => {
    deleteComment(id)
      .then((result) => {
        console.log(result);
        notification.success({
          message: DELETED_COMMENT_SUCCESS,
        });
        fetchComments(get(filter, "page"));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const viewComment = (id) => {
    getComment(id)
      .then((result) => {
        setData(result);
        showDrawerVisibility();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const showDrawerVisibility = () => {
    setShowViewDrawer(true);
  };
  const closeDrawerVisibility = () => {
    setShowViewDrawer(false);
  };
  const commentsColumns = ({ onClickEdit }) => [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Comments",
      dataIndex: "comments",
      key: "comments",
    },
    {
      title: "Action",
      render: (props) => {
        return (
          <div className="buttons-gap">
            <Button
            type="danger"
              onClick={() => removeComment(props?.uuid)}
            >
              Delete
            </Button>
            <Button
              type="primary"
              className="view-button"
              onClick={() => viewComment(props?.uuid)}
            >
              View
            </Button>
          </div>
        );
      },
    },
  ];
  const onSearch = (value) => {
    console.log(value);
    fetchComments(get(filter, "page"), value);
  };
  return (
    <Spin spinning={loading}>
      <Row justify="space-between" align="middle">
        <Col xs={12} md={12} lg={12}>
          <div className="screen-title">Comments</div>
        </Col>
      <Col xs={12} md={12} lg={6}>
        <Search
          placeholder="search by name"
          className="search-input"
          allowClear
          enterButton={false}
          suffix={false}
          size="large"
          onSearch={onSearch}
        />
      </Col>
      </Row>
      <Table
        columns={commentsColumns({})}
        dataSource={get(commentsResult, "comments")}
        scroll={{ x: true }}
        pagination={{
          pageSize: get(commentsResult, "pagination.per_page", 1),
          current: get(commentsResult, "pagination.current_page", 1),
          total: get(commentsResult, "pagination.total", 1),
          onChange: (val) => {
            fetchComments(val, get(filter, "query"));
          },
        }}
      />
       <Drawer
        className="main-drawer"
        destroyOnClose
        width={350}
        closable={false}
        visible={showViewDrawer}
      >
        <>
          <div className="add-drawer-content">
            <Row justify="space-between">
              <Col className="drawer-title">Contact Us Details</Col>
              <Col>
                <CloseOutlined onClick={closeDrawerVisibility} />
              </Col>
            </Row>
            <div className="add-drawer-form">
              <ViewContactDetails contactUsData={data}/>
            </div>
          </div>
        </>
      </Drawer>
    </Spin>
  );
};
export default requireAuth(CommentsScreen, [1]);
