import {
  CloseCircleFilled,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Spin,
  List,
  Divider,
  Radio,
} from "antd";
import React, { useEffect, useState } from "react";
import { notification } from "antd";
import get from "lodash/get";
import { useSelector } from "react-redux";
import {
  getHospitalQuestions,
  postHospitalQuestion,
  deleteHospitalQuestion,
  updateHospitalQuestion,
} from "../../redux/actions/hospitalAction";
import { DELETED_QUESTION } from "../../constants/messages";

const OPTION_TYPES = {
  RADIO: "RADIO",
  INPUT: "INPUT",
  FILE: "FILE",
  DROPDOWN: "DROPDOWN",
  CHECKBOX: "CHECKBOX",
  IMAGE : "IMAGE"
};
const QUESTION_TYPES = {
  RADIO: [
    { label: "option1", value: "" },
    { label: "option2", value: "" },
  ],
  DROPDOWN: [
    { label: "option1", value: "" },
    { label: "option2", value: "" },
  ],
  CHECKBOX: [
    { label: "option1", value: "" },
    { label: "option2", value: "" },
  ],
};

const QuestionsScreen = ({ hospital }) => {
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editQuestion, setEditQuestion] = useState(null);
  const [questionType, setQuestionType] = useState();
  const [question,setQuestion] = useState("");
  const [options, setOptions] = useState(
   { ...QUESTION_TYPES }
  );

  const onSave = () => {
    setEdit(false);
  };
  const onEdit = (itemUuid) => {
    if(itemUuid !== null || itemUuid !== undefined){
    let editQuestion = questions?.filter(
      (question) => question?.question?.uuid === itemUuid
    )?.[0];
    let options = editQuestion?.question ? JSON?.parse(editQuestion?.question?.options) :{};
    let type = editQuestion?.question?.type;
    const temp = options?.map((option, index) => {
      return { label: `option${index + 1}`, value: option };
    });
    let intialOptions = {...QUESTION_TYPES};
    intialOptions[type] = temp;
    setOptions(intialOptions);
    setQuestionType(type);
    setQuestion(editQuestion?.question);
    setEditQuestion(editQuestion?.question);
    setEdit(true);
  }
  };
  const onClickBack = () => {
    setEdit(false);
  };
  const [questions, setQuestions] = useState([]);

  const hospital_uuid = useSelector((state) =>
    get(state, "auth.currentHospital.id")
  );
  const createdBy = useSelector((state) => state?.auth?.id);

  const fetchQuestions = () => {
    setLoading(true);
    getHospitalQuestions(hospital_uuid)
      .then((result) => {
        setLoading(false);
        setQuestions(result);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const postQuestions = (payload) => {};

  useEffect(() => {
    if(hospital_uuid){
    fetchQuestions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Spin spinning={loading}>
      {edit ? (
        <EditQuestions
          onSave={onSave}
          setLoading={setLoading}
          questions={questions}
          editQuestion={editQuestion}
          postQuestions={postQuestions}
          fetchQuestions={fetchQuestions}
          hospital={hospital}
          loading={loading}
          createdBy={createdBy}
          onClickBack={onClickBack}
          questionType={questionType}
          question={question}
          options={options}
          setQuestionType={setQuestionType}
          setOptions={setOptions}
        />
      ) : (
        <Spin spinning={loading}>
        <ViewQuestions
          onEdit={onEdit}
          setLoading={setLoading}
          questions={questions}
          loading={loading}
          fetchQuestions={fetchQuestions}
          setEdit={setEdit}
        />
        </Spin>
      )}
    </Spin>
  );
};

export default QuestionsScreen;

const EditQuestions = ({
  setLoading,
  loading,
  onSave,
  questions,
  fetchQuestions,
  hospital,
  editQuestion,
  onClickBack,
  questionType,
  question,
  options,
  setQuestionType,
  setOptions
}) => {
  const onFinish = (values) => {
    let optionTemp = options[values?.type] || [];
    let temp = [];
    optionTemp?.map((option) => temp.push(option?.value));
    values["options"] = JSON.stringify(temp);
    // temp.join(',');
    setLoading(true);
    postHospitalQuestion(values)
      .then((result) => {
        setLoading(false);
        onSave();
        fetchQuestions();
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const onUpdateQuestion = (values) => {
    let optionTemp = options[editQuestion?.type] || [];
    let temp = [];
    optionTemp?.map((option) => temp.push(option?.value));
    values["options"] = JSON.stringify(temp);
    values["type"] = editQuestion?.type;
    setLoading(true);
    updateHospitalQuestion(editQuestion?.uuid, values)
      .then((result) => {
        setLoading(false);
        onSave();
        fetchQuestions();
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const handleQuestionType = (event) => {
    setQuestionType(event?.target?.value);
  };
  const handleOptions = (event, index) => {
    let { name, value } = event?.target;
    let tempOptions = { ...options };
    let singleOption = [...tempOptions[questionType]];
    singleOption[index] = { ...singleOption[index], [name]: value };
    tempOptions[questionType] = singleOption;
    setOptions({ ...tempOptions });
  };
  const removeOption = (index) => {
    let tempOptions = { ...options };
    tempOptions[questionType].splice(index, 1);
    setOptions(tempOptions);
  };
  const addOption = () => {
    let tempOptions = { ...options };
    let obj = {
      label: "option" + tempOptions[questionType]?.length + 1,
      value: "",
    };
    tempOptions[questionType].push(obj);
    setOptions(tempOptions);
  };
  return (
    <Form
      name="dynamic_form_nest_item"
      className="faqs_form"
      layout="vertical"
      onFinish={editQuestion?.id ? onUpdateQuestion : onFinish}
      autoComplete="off"
    >
      <Row
        style={{ display: "flex", marginBottom: 8 }}
        align="baseline"
        justify="space-between"
      >
        <Col span={22}>
          <Form.Item
            name={"question"}
            label=" Question "
            rules={[
              {
                required: true,
                message: "Please enter the question!",
              },
            ]}
          >
            <Input
              defaultValue={question?.question || ""}
              placeholder="Question"
              className="custom-input"
            />
          </Form.Item>
          <Col>
            <Form.Item name="type" label="Question Type " required={true}>
              <Radio.Group
                name="type"
                defaultValue={questionType}
                onChange={handleQuestionType}
              >
                <Radio value={OPTION_TYPES?.RADIO}>Radio</Radio>
                <Radio value={OPTION_TYPES.INPUT}>Input</Radio>
                <Radio value={OPTION_TYPES?.FILE}>File</Radio>
                <Radio value={OPTION_TYPES?.IMAGE}>Image</Radio>
                <Radio value={OPTION_TYPES.DROPDOWN}>Dropdown</Radio>
                <Radio value={OPTION_TYPES.CHECKBOX}>Checkbox</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Col>
        <Col>
          <CloseCircleFilled
            style={{ fontSize: "20px" }}
            // onClick={() => remove(name)}
          />
        </Col>
        {options?.[questionType]?.map((option, index) => {
          return (
            <Row>
              <div style={{ display: "flex" }}>
                <Input
                  placeholder={option?.label || "Enter Option"}
                  type="text"
                  name="value"
                  defaultValue={option?.value}
                  onChange={(event) => handleOptions(event, index)}
                />
                <CloseCircleFilled
                  style={{ fontSize: "20px" }}
                  onClick={() => removeOption(index)}
                />
              </div>
            </Row>
          );
        })}
        {options?.[questionType] ? <PlusOutlined onClick={addOption} /> : null}
      </Row>
      <Divider />
      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ marginRight: "8px" }}>
          {editQuestion?.id ? "Update" : "Submit"}
        </Button>
        <Button type="primary" onClick={onClickBack}>
          Back
        </Button>
      </Form.Item>
    </Form>
  );
};

const ViewQuestions = ({
  onEdit,
  questions,
  loading,
  setLoading,
  fetchQuestions,
  setEdit
}) => {
  const onClickEdit = (itemUuid) => {
    onEdit(itemUuid);
  };
  const onClickDelete = (itemUuid) => {
    deleteHospitalQuestion(itemUuid)
      .then((result) => {
        setLoading(false);
        notification.success({
          message: DELETED_QUESTION,
        });
        fetchQuestions();
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <>
      {questions && (
        <>
          <h3>Hospital Screening Questions</h3>
          <List
            itemLayout="horizontal"
            dataSource={questions}
            renderItem={(item) => (
              <>
                <List.Item>
                  <List.Item.Meta title="" description={item?.question?.question} />
                  <Button
                    type="primary"
                    onClick={() => onClickEdit(item?.question?.uuid)}
                    style={{ marginRight: "8px" }}
                  >
                    Edit
                  </Button>
                  <Button
                    type="danger"
                    onClick={() => onClickDelete(item?.question?.uuid)}
                    icon={<DeleteOutlined />}
                  ></Button>
                </List.Item>
              </>
            )}
          />
          <br />
          <Button type="primary" onClick={()=>setEdit((true))}>
            Create Question
          </Button>
        </>
      )}
    </>
  );
};
