import {
  CloseCircleFilled,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Spin,
  List,
  Divider,
  Radio,
  Checkbox,
} from "antd";
import React, { useEffect, useState } from "react";
import { notification } from "antd";
import get from "lodash/get";
import { useSelector } from "react-redux";
import {
  getHospitalQuestions,
} from "../../redux/actions/hospitalAction";
import { getDoctorsQuestions,postDoctorQuestion,updateDoctorQuestion,deleteDoctorQuestion,postIncludedQuestions,
  getNotIncludedDoctorQuestions,
  getIncludedDoctorQuestions } from "../../redux/actions/doctorAction";

import { DELETED_QUESTION,SUCCESS_INCLUDE_QUESTIONS } from "../../constants/messages";

const OPTION_TYPES = {
  RADIO: "RADIO",
  INPUT: "INPUT",
  FILE: "FILE",
  DROPDOWN: "DROPDOWN",
  CHECKBOX: "CHECKBOX",
  IMAGE: "IMAGE",
};
const QUESTION_TYPES = {
  RADIO: [
    { label: "option1", value: "" },
    { label: "option2", value: "" },
  ],
  DROPDOWN: [
    { label: "option1", value: "" },
    { label: "option2", value: "" },
  ],
  CHECKBOX: [
    { label: "option1", value: "" },
    { label: "option2", value: "" },
  ],
};

const DoctorQuestionsScreen = ({ hospital }) => {
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editQuestion, setEditQuestion] = useState(null);
  const [questionType, setQuestionType] = useState();
  const [question, setQuestion] = useState("");
  const [options, setOptions] = useState({ ...QUESTION_TYPES });
  const [includedQuestions,setIncludedQuestions] = useState([]);
  const [notIncludedQuestions,setNotIncludedQuestions] = useState([]);


  const onSave = () => {
    setEdit(false);
  };
  const onEdit = (itemUuid) => {
    if (itemUuid !== null || itemUuid !== undefined) {
      let editQuestion = doctorQuestions?.filter(
        (question) => question?.question?.uuid === itemUuid
      )?.[0];
      let options = editQuestion?.question ? JSON?.parse(editQuestion?.question?.options) : {};
      let type = editQuestion?.question?.type;
      const temp = options?.map((option, index) => {
        return { label: `option${index + 1}`, value: option };
      });

      let intialOptions = { ...QUESTION_TYPES };
      intialOptions[type] = temp;
      setOptions(intialOptions);
      setQuestionType(type);
      setQuestion(editQuestion?.question);
      setEditQuestion({...editQuestion?.question});
      setEdit(true);
    }
  };
  const onClickBack = () => {
    setEdit(false);
  };
  const [hospitalQuestions, setHospitalQuestions] = useState([]);
  const [doctorQuestions, setDoctorQuestions] = useState();
  const hospital_uuid = useSelector((state) =>
    get(state, "auth.currentHospital.id")
  );
  const doctor_user_uuid = useSelector((state) =>state?.auth.user?.id);
  const createdBy = useSelector((state) => state?.auth?.id);

  const fetchHospitalQuestions = () => {
    getHospitalQuestions(hospital_uuid)
      .then((result) => {
        setHospitalQuestions(result);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const fetchDoctorQuestions = () => {
    getDoctorsQuestions(doctor_user_uuid)
      .then((result) => {
        setDoctorQuestions(result);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const fetchIncludedDoctorQuestions=()=>{
    setLoading(true);
    getIncludedDoctorQuestions(doctor_user_uuid).then((result)=>{
      setIncludedQuestions(result?.map(question=>question?.question?.uuid));
      setLoading(false);
    }).catch((error)=>{
      setLoading(false);
    })
  }
  const fetchNotIncludedDoctorQuestions=()=>{
    setLoading(true);
    getNotIncludedDoctorQuestions(doctor_user_uuid).then((result)=>{
      setNotIncludedQuestions(result?.map(question=>question?.question?.uuid));
      setLoading(false);
    }).catch((error)=>{
      setLoading(false);
    })
  }

  const postQuestions = (payload) => {};
  const onClickCreate=()=>{
    setEditQuestion(null);
    setOptions({ ...QUESTION_TYPES });
    setEdit(true);
  }

  useEffect(() => {
    if(hospital_uuid && doctor_user_uuid){
    fetchHospitalQuestions();
    fetchDoctorQuestions();
    fetchIncludedDoctorQuestions();
    fetchNotIncludedDoctorQuestions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Spin spinning={loading}>
      {edit ? (
        <EditQuestions
          onSave={onSave}
          setLoading={setLoading}
          hospitalQuestions={hospitalQuestions}
          doctorQuestions={doctorQuestions}
          editQuestion={editQuestion}
          postQuestions={postQuestions}
          fetchHospitalQuestions={fetchHospitalQuestions}
          hospital={hospital}
          loading={loading}
          createdBy={createdBy}
          onClickBack={onClickBack}
          questionType={questionType}
          question={question}
          options={options}
          setQuestionType={setQuestionType}
          setOptions={setOptions}
          includedQuestions={includedQuestions}
          setIncludedQuestions={setIncludedQuestions}
          doctor_user_uuid={doctor_user_uuid}
          fetchDoctorQuestions={fetchDoctorQuestions}
        />
      ) : (
        <>
          <ViewQuestions
            onEdit={onEdit}
            setLoading={setLoading}
            hospitalQuestions={hospitalQuestions}
            loading={loading}
            fetchHospitalQuestions={fetchHospitalQuestions}
            setEdit={setEdit}
            includedQuestions={includedQuestions}
            setIncludedQuestions={setIncludedQuestions}
            doctor_user_uuid={doctor_user_uuid}
            hospital_uuid={hospital_uuid}
            notIncludedQuestions={notIncludedQuestions}
            setNotIncludedQuestions={setNotIncludedQuestions}
          />
          <ViewDoctorQuestions
            onEdit={onEdit}
            setLoading={setLoading}
            doctorQuestions={doctorQuestions}
            loading={loading}
            fetchHospitalQuestions={fetchHospitalQuestions}
            fetchDoctorQuestions={fetchDoctorQuestions}
            onClickCreate={onClickCreate}
          />
        </>
      )}
    </Spin>
  );
};

export default DoctorQuestionsScreen;

const EditQuestions = ({
  setLoading,
  loading,
  onSave,
  doctorQuestions,
  fetchHospitalQuestions,
  hospital,
  editQuestion,
  onClickBack,
  questionType,
  question,
  options,
  setQuestionType,
  setOptions,
  includedQuestions,
  setIncludedQuestions,
  doctor_user_uuid,
  fetchDoctorQuestions
}) => {
  const onFinish = (values) => {
    let optionTemp = options?.[values?.type] || [];
    let temp = [];
    optionTemp?.map((option) => temp.push(option?.value));
    values["options"] = JSON.stringify(temp);
    values["includedQuestions"] = includedQuestions;
    values['doctor_user_uuid'] = doctor_user_uuid;
    // temp.join(',');
    setLoading(true);
    postDoctorQuestion(values)
      .then((result) => {
        setLoading(false);
        onSave();
        fetchHospitalQuestions();
        fetchDoctorQuestions();
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const onUpdateQuestion = (values) => {
    let optionTemp = options[editQuestion?.type] || [];
    let temp = [];
    optionTemp?.map((option) => temp.push(option?.value));
    values["options"] = JSON.stringify(temp);
    setLoading(true);
    updateDoctorQuestion(editQuestion?.uuid, values)
      .then((result) => {
        setLoading(false);
        onSave();
        fetchHospitalQuestions();
        fetchDoctorQuestions();
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const handleQuestionType = (event) => {
    setQuestionType(event?.target?.value);
  };
  const handleOptions = (event, index) => {
    let { name, value } = event?.target;
    let tempOptions = { ...options };
    let singleOption = [...tempOptions[questionType]];
    singleOption[index] = { ...singleOption[index], [name]: value };
    tempOptions[questionType] = singleOption;
    setOptions({ ...tempOptions });
  };
  const removeOption = (index) => {
    let tempOptions = { ...options };
    tempOptions[questionType].splice(index, 1);
    setOptions(tempOptions);
  };
  const addOption = () => {
    let tempOptions = { ...options };
    let obj = {
      label: "option" + tempOptions[questionType]?.length + 1,
      value: "",
    };
    tempOptions[questionType].push(obj);
    setOptions(tempOptions);
  };
  return (
    <Form
      name="dynamic_form_nest_item"
      className="faqs_form"
      layout="vertical"
      onFinish={editQuestion?.id ? onUpdateQuestion : onFinish}
      initialValues={editQuestion}
      autoComplete="off"
    >
      <Row
        style={{ display: "flex", marginBottom: 8 }}
        align="baseline"
        justify="space-between"
      >
        <Col span={22}>
          <Form.Item
            name={"question"}
            label=" Question "
            rules={[
              {
                required: true,
                message: "Please enter the question!",
              },
            ]}
          >
            <Input
              // defaultValue={question?.question || ""}
              placeholder="Question"
              className="custom-input"
            />
          </Form.Item>
          <Col>
            <Form.Item name="type" label="Question Type " required={true}>
              <Radio.Group
                name="type"
                // defaultValue={questionType}
                onChange={handleQuestionType}
              >
                <Radio value={OPTION_TYPES?.RADIO}>Radio</Radio>
                <Radio value={OPTION_TYPES.INPUT}>Input</Radio>
                <Radio value={OPTION_TYPES?.FILE}>File</Radio>
                <Radio value={OPTION_TYPES?.IMAGE}>Image</Radio>
                <Radio value={OPTION_TYPES.DROPDOWN}>Dropdown</Radio>
                <Radio value={OPTION_TYPES.CHECKBOX}>Checkbox</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Col>
        <Col>
          <CloseCircleFilled
            style={{ fontSize: "20px" }}
            // onClick={() => remove(name)}
          />
        </Col>
        {options?.[questionType]?.map((option, index) => {
          return (
            <Row>
              <div style={{ display: "flex" }}>
                <Input
                  placeholder={option?.label || "Enter Option"}
                  type="text"
                  name="value"
                  defaultValue={option?.value}
                  onChange={(event) => handleOptions(event, index)}
                />
                <CloseCircleFilled
                  style={{ fontSize: "20px" }}
                  onClick={() => removeOption(index)}
                />
              </div>
            </Row>
          );
        })}
        {options?.[questionType] ? <PlusOutlined onClick={addOption} /> : null}
      </Row>
      <Divider />
      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ marginRight: "8px" }}>
          {editQuestion?.id ? "Update" : "Submit"}
        </Button>
        <Button type="primary" onClick={onClickBack}>
          Back
        </Button>
      </Form.Item>
    </Form>
  );
};

const ViewQuestions = ({
  onEdit,
  hospitalQuestions,
  loading,
  setLoading,
  fetchHospitalQuestions,
  setEdit,
  includedQuestions,
  setIncludedQuestions,
  doctor_user_uuid,
  hospital_uuid,
  notIncludedQuestions,
  setNotIncludedQuestions
}) => {
  const handleIncludeQuestions=(item,event)=>{
    let included = [...includedQuestions];
    let notIncluded = [...notIncludedQuestions];
    if(event?.target?.value === "include"){
      included.push(item);
      notIncluded.splice(notIncluded.indexOf(item),1);
    }
    if(event?.target?.value === "notInclude"){
      included.splice(included.indexOf(item),1);
      notIncluded.push(item);
    }
    setIncludedQuestions(included);
    setNotIncludedQuestions(notIncluded);
  }
  const submitIncludedQuestions=()=>{
    setLoading(true);
    const data = {
      includedQuestions : includedQuestions,
      notIncludedQuestions : notIncludedQuestions,
      doctor_user_uuid : doctor_user_uuid,
      hospital_uuid : hospital_uuid
    }
    postIncludedQuestions(data)
      .then((result) => {
        setLoading(false);
        fetchHospitalQuestions();
        notification.success({message : (SUCCESS_INCLUDE_QUESTIONS)})
      })
      .catch((error) => {
        setLoading(false);
      });
  }
  return (
    <>
      {hospitalQuestions && (
        <>
          <h3>
            <b>Hospital & Department Questions</b>
          </h3>
          <List
            itemLayout="horizontal"
            dataSource={hospitalQuestions}
            renderItem={(item,index) => (
              <>
                <List.Item>
                  <List.Item.Meta title="" description={item?.question?.question} />
                  <Radio.Group
                    name={"type"+index}
                    value={includedQuestions?.indexOf(item?.question?.uuid)>-1 ? "include" : "notInclude"}
                    onChange={(event)=>handleIncludeQuestions(item?.question?.uuid,event)}
                  >
                    <Radio value="include">Include</Radio>
                    <Radio value="notInclude">Not Include</Radio>
                  </Radio.Group>
                </List.Item>
              </>
            )}
          />
          <br />
          <Button type="primary" onClick={submitIncludedQuestions}>
              Submit Included Questions
          </Button>
          <br/>
        </>
      )}
    </>
  );
};

const ViewDoctorQuestions = ({
  onEdit,
  doctorQuestions,
  loading,
  setLoading,
  fetchHospitalQuestions,
  fetchDoctorQuestions,
  onClickCreate
}) => {
  const onClickEdit = (itemUuid) => {
    onEdit(itemUuid);
  };
  const onClickDelete = (itemUuid) => {
    deleteDoctorQuestion(itemUuid)
      .then((result) => {
        setLoading(false);
        notification.success({
          message: DELETED_QUESTION,
        });
        fetchHospitalQuestions();
        fetchDoctorQuestions();
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <>
      {doctorQuestions && (
        <>
          <h3 style={{marginTop:"12px"}}>
            <b>created Questions</b>
          </h3>
          <List
            itemLayout="horizontal"
            dataSource={doctorQuestions}
            renderItem={(item) => (
              <>
                <List.Item>
                  <List.Item.Meta title="" description={item?.question?.question} />
                  <Button
                    type="primary"
                    onClick={() => onClickEdit(item?.question?.uuid)}
                    style={{ marginRight: "8px" }}
                  >
                    Edit
                  </Button>
                  <Button
                    type="danger"
                    onClick={() => onClickDelete(item?.question?.uuid)}
                    icon={<DeleteOutlined />}
                  ></Button>
                </List.Item>
              </>
            )}
          />
          <br />
          <Button type="primary" onClick={onClickCreate}>
            Create Question
          </Button>
        </>
      )}
    </>
  );
};
