import {
  CloseCircleFilled,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Spin,
  List,
  Divider,
  Checkbox,
  message
} from "antd";
import React, { useEffect, useState } from "react";
import { DatePicker, Space, notification } from "antd";
import get from "lodash/get";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  getHospitalNotifications,
  postHospitalNotifications,
  deleteHospitalNotifications,
  updateHospitalNotifications,
  fetchHospitalNotificationById
} from "../../redux/actions/hospitalAction";
import { DELETED_NOTIFICATIONS } from "../../constants/messages";
import SingleImageUpload from "../../components/mediaUpload/SingleImageUpload";
import { useForm } from "antd/lib/form/Form";


const NotificationsScreen = ({ hospital }) => {
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editNotification, setEditNotification] = useState(null);
  const [imageUser, setUserImage] = useState();
  const [form] = useForm();
  const onSave = () => {
    setEdit(false);
  };
  const onEdit = (itemUuid,onEdit=false) => {
    setLoading(true);
    setEdit(true);
    if(onEdit){
    fetchHospitalNotificationById(itemUuid).then((res)=>{
      setLoading(false);
      let result = {...res,start_date : moment(res?.start_date, 'DD-MM-YYYY'), end_date : moment(res?.end_date,"DD-MM-YYYY")};
      form.setFieldsValue(result);
      setEditNotification(result);
      setUserImage(res?.image);
    })
  }
  };
  const onClickBack = () => {
    setEdit(false);
    form.resetFields();
    setEditNotification({})
    setUserImage({})
  };
  const [notifications, setNotifications] = useState([]);

  const hospital_uuid = useSelector((state) =>
    get(state, "auth.currentHospital.id")
  );
  const createdBy = useSelector((state) => state?.auth?.id);

  const fetchNotifications = () => {
    setLoading(true);
    getHospitalNotifications(hospital_uuid)
      .then((result) => {
        setLoading(false);
        setNotifications(result);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const postNotifications = (payload) => {};

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <Spin spinning={loading}>
      {edit ? (
        <EditNotifications
          onSave={onSave}
          setLoading={setLoading}
          notifications={notifications}
          editNotification={editNotification}
          postNotifications={postNotifications}
          fetchNotifications={fetchNotifications}
          hospital={hospital}
          loading={loading}
          createdBy={createdBy}
          onClickBack={onClickBack}
          imageUser={imageUser}
          setUserImage={setUserImage}
          form={form}
        />
      ) : (
        <ViewNotifications
          onEdit={onEdit}
          setLoading={setLoading}
          notifications={notifications}
          loading={loading}
          fetchNotifications={fetchNotifications}
        />
      )}
    </Spin>
  );
};

export default NotificationsScreen;

const EditNotifications = ({
  setLoading,
  loading,
  onSave,
  notifications,
  fetchNotifications,
  hospital,
  createdBy,
  editNotification,
  onClickBack,
  imageUser,
  setUserImage,
  form
}) => {
  const onFinish = (values) => {
    let data = {};
    data["isSponsor"] = componentDisabled;
    data["start_date"] = values?.start_date?.format("DD-MM-YYYY");
    data["end_date"] = values?.end_date?.format("DD-MM-YYYY");
    data["image"] = imageUser?.key || values?.image;
    data["text"] = values?.text;
    data["createdBy"] = createdBy;
    const convertedDate1 = new Date(
      values?.start_date?.format("DD-MM-YYYY").split("-").reverse().join("-")
    );
    const convertedDate2 = new Date(
      values?.end_date?.format("DD-MM-YYYY").split("-").reverse().join("-")
    );
    if (values?.start_date?.format("DD-MM-YYYY") === undefined) {
      message.error("Please Select Start Date");
      return "";
    }
    if (convertedDate1 > convertedDate2) {
      message.success("End Date is lesser than Start Date");
    } else {
    setLoading(true);
    postHospitalNotifications(data)
      .then((result) => {
        setLoading(false);
        onSave();
        fetchNotifications();
      })
      .catch((error) => {
        setLoading(false);
      });
    }
  };
  const onUpdateNotifications = (values) => {
    let data = {};
    data["isSponsor"] = componentDisabled;
    data["start_date"] = values?.start_date?.format("DD-MM-YYYY");
    data["end_date"] = values?.end_date?.format("DD-MM-YYYY");
    data["image"] = imageUser?.key;
    data["text"] = values?.text;
    data["createdBy"] = createdBy;
    const convertedDate1 = new Date(
      values?.start_date?.format("DD-MM-YYYY").split("-").reverse().join("-")
    );
    const convertedDate2 = new Date(
      values?.end_date?.format("DD-MM-YYYY").split("-").reverse().join("-")
    );
    if (values?.start_date?.format("DD-MM-YYYY") === undefined) {
      message.error("Please Select Start Date");
      return "";
    }
    if (values?.end_date?.format("DD-MM-YYYY") === undefined) {
      message.error("Please Select End Date");
      return "";
    }
    if (convertedDate1 > convertedDate2) {
      message.error("End Date is lesser than Start Date");
    } else {
    setLoading(true);
    updateHospitalNotifications(editNotification?.uuid, data)
      .then((result) => {
        setLoading(false);
        onSave();
        fetchNotifications();
      })
      .catch((error) => {
        setLoading(false);
      });
    }
  };

  const dateFormat = "DD-MM-YYYY";
  const currentDate = new Date();
  const day = String(currentDate.getDate()).padStart(2, "0");
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month starts from 0 (January is 0)
  const year = currentDate.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;
  // const [start_date, setStart_date] = useState(
  //   editNotification?.start_date || "28-01-2022"
  // );
  // const [end_date, setEnd_date] = useState(
  //   editNotification?.end_date || "29-01-2022"
  // );
  const [start_date, setStart_date] = useState(
    editNotification?.id ? moment(editNotification.start_date, "DD-MM-YYYY") :  moment(formattedDate, "DD-MM-YYYY")
  );
  const [end_date, setEnd_date] = useState(editNotification?.id ? moment(editNotification.end_date, "DD-MM-YYYY") : moment(formattedDate, "DD-MM-YYYY"));
  const [componentDisabled, setComponentDisabled] = useState(
    editNotification?.isSponsor || false
  );
  const onUploadLogoDone = (imageResponse) => {
    setUserImage(imageResponse);
  };
  return (
    <Form
      name="dynamic_form_nest_item"
      className="faqs_form"
      layout="vertical"
      onFinish={editNotification?.id ? onUpdateNotifications : onFinish}
      autoComplete="off"
      form={form}
    >
      <Row
        style={{ display: "flex", marginBottom: 8 }}
        align="baseline"
        justify="space-between"
      >
        <Col span={22}>
          <Form.Item
            name="text"
            label=" Text "
            rules={[
              {
                required: true,
                message: "Please enter the text!",
              },
            ]}
          >
            <Input
              placeholder="Text"
              className="custom-input"
            />
          </Form.Item>
          <Form.Item name="image" label=" Image ">
            <SingleImageUpload
              className="user-image"
              onUploadDone={onUploadLogoDone}
              imageUrl={get(imageUser, "url")}
              setImageUser={setUserImage}
            />
          </Form.Item>
          <Form.Item name="isSponsor" label=" isSponsor ">
            <Checkbox
              checked={componentDisabled}
              onChange={(e) => setComponentDisabled(e.target.checked)}
            ></Checkbox>
          </Form.Item>
          <Form.Item name="start_date" label="Start Date ">
            <DatePicker
              format={dateFormat}
            />
          </Form.Item>
          <Form.Item name="end_date" label="End Date ">
            <DatePicker
              format={dateFormat}
            />
          </Form.Item>
        </Col>
        <Col>
          <CloseCircleFilled
            style={{ fontSize: "20px" }}
            // onClick={() => remove(name)}
          />
        </Col>
      </Row>
      <Divider />
      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ marginRight: "8px" }}>
          {editNotification?.id ? "Update" : "Submit"}
        </Button>
        <Button type="primary" onClick={onClickBack}>
          Back
        </Button>
      </Form.Item>
    </Form>
  );
};

const ViewNotifications = ({
  onEdit,
  notifications,
  setLoading,
  fetchNotifications,
  loading
}) => {
  const onClickEdit = (itemUuid) => {
    onEdit(itemUuid,true);
  };
  const onClickDelete = (itemUuid) => {
    setLoading(true);
    deleteHospitalNotifications(itemUuid)
      .then((result) => {
        setLoading(false);
        notification.success({
          message: DELETED_NOTIFICATIONS,
        });
        fetchNotifications();
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <Spin spinning={loading}>
    <>
      {notifications && (
        <>
          <h3>Hospital Notifications or Offers</h3>
          <List
            itemLayout="horizontal"
            dataSource={notifications}
            renderItem={(item) => (
              <>
                <List.Item>
                  <List.Item.Meta title="" description={item?.text} />
                  <Button
                    type="primary"
                    onClick={() => onClickEdit(item?.uuid)}
                    style={{ marginRight: "8px" }}
                  >
                    Edit
                  </Button>
                  <Button
                    type="danger"
                    onClick={() => onClickDelete(item?.uuid)}
                    icon={<DeleteOutlined />}
                  ></Button>
                </List.Item>
              </>
            )}
          />
          <br />
          <Button type="primary" onClick={onEdit}>
            Create Notification
          </Button>
        </>
      )}
    </>
    </Spin>
  );
};
