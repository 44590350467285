import { Route } from 'react-router-dom';
import * as ROUTES from '../constants/routes';

import DashboardScreen from '../screens/DashboardScreen/DashboardScreen';
import DoctorsScreen from '../screens/DoctorsScreen/DoctorsScreen';
import AppointmentsScreen from '../screens/AppointmentsScreen/AppointmentsScreen';
import DepartmentsScreen from '../screens/DepartmentsScreen/DepartmentsScreen';
import { useState } from 'react';
import get from 'lodash/get';
import HospitalAdminsScreen from '../screens/HospitalAdminsScreen/HospitalAdminsScreen';
import LoginScreen from '../screens/LoginScreen/LoginScreen';
import ForgotPasswordScreen from '../screens/ForgotPasswordScreen/ForgotPasswordScreen';
import ResetPasswordScreen from '../screens/ResetPasswordScreen/ResetPasswordScreen';
import CreateAccountScreen from '../screens/CreateAccountScreen/CreateAccountScreen';
import AvailabilityScreen from '../screens/AvailabilityScreen/AvailabilityScreen';
import RegisterDoctorScreen from '../screens/RegsiterDoctorScreen/RegisterDoctorScreen';
import RegsiterStaffScreen from '../screens/RegisterStaff/RegisterStaff';
import AssistantsScreen from '../screens/AssistantsScreen/AssistantsScreen';
import HospitalDetailsScreen from '../screens/HospitalDetailsScreen/HospitalDetailsScreen';
import SettingsScreen from '../screens/SettingsScreen/SettingsScreen';
import TestScreen from '../screens/testscreen';
import ContactUsScreen from '../screens/ContactUsScreen/ContactUsScreen';
import CommentsScreen from '../screens/CommentsScreen/CommentsScreen';
import AdsScreen from '../screens/AdsScreen/AdsScreen';
import TvAdsScreen from "../screens/TvAdsScreen/TvAdsScreen";
import NotificationsScreen from '../screens/NotificationsScreen/NotificationsScreen';
import QuestionsScreen from '../screens/QuestionsScreen/QuestionsScreen';
import HospitalAgentsScreen from '../screens/HospitalAgentsScreen/HospitalAgentsScreen';
import RegisterHospitalAgentScreen from '../screens/RegisterHospitalAgentScreen/RegisterHospitalAgentScreen';
import DoctorQuestionsScreen from '../screens/DoctorQuestionsScreen/DoctorQuestionsScreen';
import MediaAndAdsScreen from '../screens/MediaAndAdsScreen.js/MediaAndAdsScreeen';
import IndividualTvAdsScreen from '../screens/IndividualTvAdsScreen/IndividualTvAdsScreen';
import CurrentTvAdsScreen from '../screens/CurrentTvAdsScreen/CurrentTvAdsScreen';


export default function AuthRouter() {
  const [doctorDetailsDrawer, setDoctorDetailsDrawer] = useState({
    visible: false,
    edit: false,
    data: null,
  });

  const [linkModal, setLinkModal] = useState(false);

  const toggleDoctorDetails = (data = null) => {
    setDoctorDetailsDrawer({
      visible: !get(doctorDetailsDrawer, 'visible'),
      edit: !get(doctorDetailsDrawer, 'visible'),
      data,
    });
  };

  return (
    <>
      <Route path={'/test'} exact component={() => <TestScreen />} />

      <Route
        path={ROUTES.DASHBOARD}
        exact
        component={() => <DashboardScreen />}
      />
      <Route
        path={ROUTES.REGISTER_DOCTOR}
        exact
        component={() => <RegisterDoctorScreen />}
      />
      <Route
        path={ROUTES.REGISTER_HOSPITAL_AGENT}
        exact
        component={() => <RegisterHospitalAgentScreen />}
      />
      <Route
        path={ROUTES.REGISTER_STAFF}
        exact
        component={() => <RegsiterStaffScreen />}
      />

      <Route path="/" exact component={() => <LoginScreen />} />
      <Route
        path={ROUTES.APPOINTMENTS}
        exact
        component={() => <AppointmentsScreen />}
      />
      <Route path={ROUTES.LOGIN} exact component={() => <LoginScreen />} />

      <Route
        path={ROUTES.AVAILABILITY}
        exact
        component={(prop) => {
          return <AvailabilityScreen />;
        }}
      />

      <Route
        path={ROUTES.ASSISTANTS}
        exact
        component={() => <AssistantsScreen />}
      />

      <Route
        path={ROUTES.DOCTORS}
        exact
        component={(props) => {
          return (
            <DoctorsScreen
              toggleDoctorDetails={toggleDoctorDetails}
              setLinkModal={setLinkModal}
              linkModal={linkModal}
            />
          );
        }}
      />
      <Route
        path={ROUTES.HOSPITAL_DETAILS}
        exact
        component={(props) => {
          return <HospitalDetailsScreen />;
        }}
      />
      <Route
        path={ROUTES.DEPARTMENTS}
        exact
        component={(props) => {
          return (
            <DepartmentsScreen
              toggleDoctorDetails={toggleDoctorDetails}
              setLinkModal={setLinkModal}
              linkModal={linkModal}
            />
          );
        }}
      />
      <Route
        path={ROUTES.HOSPITAL_ADMINS}
        exact
        component={(props) => {
          return <HospitalAdminsScreen />;
        }}
      />
      <Route 
      path={ROUTES.CONTACT_US}
      exact
      component={(props)=>{
        return <ContactUsScreen/>
      }}/>
      <Route 
      path={ROUTES.ADS}
      exact
      component={(props)=>{
        return <AdsScreen/>
      }}/>
      <Route 
      path={ROUTES.NOTIFICATIONS}
      exact
      component={(props)=>{
        return <NotificationsScreen/>
      }}/>
      <Route 
      path={ROUTES.QUESTIONS}
      exact
      component={(props)=>{
        return <QuestionsScreen/>
      }}/>
      <Route 
      path={ROUTES.TV_ADS}
      exact
      component={(props)=>{
        return <TvAdsScreen/>
      }}/>
      <Route 
      path={ROUTES.MEDIA_AND_ADS}
      exact
      component={(props)=>{
        return <MediaAndAdsScreen/>
      }}/>
      <Route 
      path={ROUTES.CURRENT_TV_ADS}
      exact
      component={(props)=>{
        return <IndividualTvAdsScreen/>
      }}/>
      <Route 
      path={ROUTES.CURRENT_DATE_TV_ADS}
      exact
      component={(props)=>{
        return <CurrentTvAdsScreen/>
      }}/>
      <Route 
      path={ROUTES.COMMENTS}
      exact
      component={(props)=>{
        return <CommentsScreen/>
      }}/>
      <Route 
      path={ROUTES.HOSPITAL_AGENTS}
      exact
      component={(props)=>{
        return <HospitalAgentsScreen/>
      }}/>
      <Route 
      path={ROUTES.DOCTOR_QUESTIONS}
      exact
      component={(props)=>{
        return <DoctorQuestionsScreen/>
      }}/>
      {/* <Route 
      path={ROUTES.HOSPITAL_ADMINS}
      exact
      component={(props)=>{
        return <ContactUsScreen/>
      }}/> */}
      <Route
        path={ROUTES.FORGOT_PASSWORD}
        exact
        component={() => <ForgotPasswordScreen />}
      />
      <Route
        path={ROUTES.RESET_PASSWORD}
        exact
        component={() => <ResetPasswordScreen />}
      />
      <Route
        path={ROUTES.CREATE_ACCOUNT}
        exact
        component={() => <CreateAccountScreen />}
      />
      <Route
        path={ROUTES.SETTINGS}
        exact
        component={() => <SettingsScreen />}
      />
    </>
  );
}
