import React, { useEffect, useState } from "react";
import { TimePicker, Form, Input } from "antd";
import { Button, Col, Radio, notification } from "antd";
import { updateDoctorDelay } from "../../redux/actions/doctorAction";
import { UPDATED_DOCTOR_DELAY } from "../../constants/messages";
import moment from "moment";

const ShowDelayScreen = ({
  doctor_uuid,
  hospital_uuid,
  clickSubmit,
  closeDelayModal,
  currentSlot,
  slotType,
}) => {
  const [loading, setLoading] = useState(false);
  const [duration, setDuration] = useState("15");
  const [openHours, setOpenHours] = useState([]);
  const [startTime, setStartTime] = useState(moment());
  const [endTime, setEndTime] = useState(moment());

  const handleStartTimeChange = (time, timeString) => {
    setStartTime(time);
  };
  const handleEndTimeChange = (time, timeString) => {
    setEndTime(time);
  };
  const getFormattedRange = (currentTime) => {
    const formattedTime = currentTime.format("HH:mm:ss");
    const originalTime = moment(formattedTime, "HH:mm:ss");
    const formattedOriginalTime = originalTime.format("HH:mm");
    return formattedOriginalTime;
  };
  const submitAvailability = (FormValues) => {
    setDuration(FormValues.duration);
    setLoading(true);
    const values = {
      delay: FormValues.duration,
      delay_time: `${getFormattedRange(startTime)}- ${getFormattedRange(
        endTime
      )}`,
    };
    setLoading(true);
    updateDoctorDelay(doctor_uuid, values)
      .then((result) => {
        setLoading(false);
        notification.success({
          message: UPDATED_DOCTOR_DELAY,
        });
        closeDelayModal();
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <div style={{ marginTop: "24px" }}>
      <h1>Select Appointment Delay</h1>
      <div style={{ display: "flex" }}>
        <div className="form-label">
          Select Start Time
          <div>
            <TimePicker
              value={startTime}
              onChange={handleStartTimeChange}
              format="HH:mm"
              style={{ marginBottom: "12px" }}
            />
          </div>
        </div>
        <div className="form-label" style={{ marginLeft: "12px" }}>
          Select End Time
          <div>
            <TimePicker
              value={endTime}
              onChange={handleEndTimeChange}
              format="HH:mm"
              style={{ marginBottom: "12px" }}
            />
          </div>
        </div>
      </div>
      <Form onFinish={submitAvailability}>
      <Form.Item
        label="Duration"
        name="duration"
        rules={[
          {
            required: true,
            type: "duration",
          },
        ]}
      >
        <Input
          className="custom-input"
          placeholder="Enter Duration in minutes "
        />
      </Form.Item>
      <div>
        <Button htmlType="submit" type="primary" >
          Save
        </Button>
      </div>
      </Form>
    </div>
  );
};
export default ShowDelayScreen;
