import {
  CloseCircleFilled,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Spin,
  List,
  Divider,
  message,
  Checkbox,
} from "antd";
import React, { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { DatePicker, notification } from "antd";
import get from "lodash/get";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  getHospitalAds,
  postHospitalAds,
  deleteHospitalAds,
  updateHospitalAds,
  fetchHospitalAdById,
} from "../../redux/actions/hospitalAction";
import { DELETED_ADS } from "../../constants/messages";
import SingleImageUpload from "../../components/mediaUpload/SingleImageUpload";
import SingleVideoUpload from "../../components/mediaUpload/SingleVideoUpload";
import { useForm } from "antd/lib/form/Form";

const AdsScreen = ({ hospital }) => {
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editAd, setEditAd] = useState(null);
  const [imageUser, setUserImage] = useState();
  const [videoUser, setUserVideo] = useState();
  const [form] = useForm();

  const onSave = () => {
    setEdit(false);
  };
  const onEdit = (itemUuid,onEdit=false) => {
    setEdit(true);
    if(onEdit){
    fetchHospitalAdById(itemUuid).then((res) => {
      let result = {...res,start_date : moment(res?.start_date, 'DD-MM-YYYY'), end_date : moment(res?.end_date,"DD-MM-YYYY")}
      form.setFieldsValue(result);
      setEditAd(result);
      setUserImage(res?.image);
      setUserVideo(res?.video);
    });
    }
  };
  const onClickBack = () => {
    setEdit(false);
    form.resetFields();
    setEditAd({})
    setUserImage({})
  };
  const [ads, setAds] = useState([]);

  const hospital_uuid = useSelector((state) =>
    get(state, "auth.currentHospital.id")
  );
  const createdBy = useSelector((state) => state?.auth?.id);

  const fetchAds = () => {
    setLoading(true);
    getHospitalAds(hospital_uuid)
      .then((result) => {
        setLoading(false);
        setAds(result);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const postAds = (payload) => {};

  useEffect(() => {
    fetchAds();
  }, []);

  return (
    <Spin spinning={loading}>
      {edit ? (
        <EditAds
          onSave={onSave}
          setLoading={setLoading}
          ads={ads}
          editAd={editAd}
          postAds={postAds}
          fetchAds={fetchAds}
          hospital={hospital}
          loading={loading}
          createdBy={createdBy}
          onClickBack={onClickBack}
          imageUser={imageUser}
          setUserImage={setUserImage}
          videoUser={videoUser}
          setUserVideo={setUserVideo}
          form={form}
        />
      ) : (
        <ViewAds
          onEdit={onEdit}
          setLoading={setLoading}
          ads={ads}
          loading={loading}
          fetchAds={fetchAds}
          imageUser={imageUser}
          setUserImage={setUserImage}
          videoUser={videoUser}
          setUserVideo={setUserVideo}
        />
      )}
    </Spin>
  );
};

export default AdsScreen;

const EditAds = ({
  setLoading,
  loading,
  onSave,
  ads,
  fetchAds,
  hospital,
  createdBy,
  editAd,
  onClickBack,
  imageUser,
  setUserImage,
  videoUser,
  setUserVideo,
  form
}) => {

  const onFinish = (values) => {
    let data = {};
    data["isSponsor"] = componentDisabled;
    data["start_date"] = values?.start_date?.format("DD-MM-YYYY");
    data["end_date"] = values?.end_date?.format("DD-MM-YYYY");
    data["image"] = imageUser?.key || values?.image;
    data["text"] = editAd ? editAd?.text : values?.text;
    data["video"] = videoUser?.key || values?.video;
    data["createdBy"] = createdBy;
    const convertedDate1 = new Date(
      values?.start_date?.format("DD-MM-YYYY").split("-").reverse().join("-")
    );
    const convertedDate2 = new Date(
      values?.end_date?.format("DD-MM-YYYY").split("-").reverse().join("-")
    );
    if (values?.start_date?.format("DD-MM-YYYY") === undefined) {
      message.error("Please Select Start Date");
      return "";
    }
    if (convertedDate1 > convertedDate2) {
      message.success("End Date is lesser than Start Date");
    } else {
      setLoading(true);
      postHospitalAds(data)
        .then((result) => {
          setLoading(false);
          onSave();
          fetchAds();
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };
  const onUpdateAds = (values) => {
    let data = {};
    data["isSponsor"] = componentDisabled;
    data["start_date"] = values?.start_date?.format("DD-MM-YYYY");
    data["end_date"] = values?.end_date?.format("DD-MM-YYYY");
    data["image"] = imageUser?.key;
    data["text"] = editAd ? editAd?.text : values?.text;
    data["video"] = videoUser?.key;
    data["createdBy"] = createdBy;
    const convertedDate1 = new Date(
      values?.start_date?.format("DD-MM-YYYY").split("-").reverse().join("-")
    );
    const convertedDate2 = new Date(
      values?.end_date?.format("DD-MM-YYYY").split("-").reverse().join("-")
    );
    if (values?.start_date?.format("DD-MM-YYYY") === undefined) {
      message.error("Please Select Start Date");
      return "";
    }
    if (values?.end_date?.format("DD-MM-YYYY") === undefined) {
      message.error("Please Select End Date");
      return "";
    }
    if (convertedDate1 > convertedDate2) {
      message.error("End Date is lesser than Start Date");
    } else {
      setLoading(true);
      updateHospitalAds(editAd?.uuid, data)
        .then((result) => {
          setLoading(false);
          onSave();
          fetchAds();
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };
  const dateFormat = "DD-MM-YYYY";
  const currentDate = new Date();
  const day = String(currentDate.getDate()).padStart(2, "0");
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month starts from 0 (January is 0)
  const year = currentDate.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;
  const [start_date, setStart_date] = useState(
    editAd?.id ? moment(editAd.start_date, "DD-MM-YYYY") :  moment(formattedDate, "DD-MM-YYYY")
  );
  const [end_date, setEnd_date] = useState(editAd?.id ? moment(editAd.end_date, "DD-MM-YYYY") : moment(formattedDate, "DD-MM-YYYY"));
  const [componentDisabled, setComponentDisabled] = useState(editAd?.isSponsor);
  const onUploadLogoDone = (imageResponse) => {
    setUserImage(imageResponse);
  };
  const onUploadVideoDone = (videoResponse) => {
    setUserVideo(videoResponse);
  };
  return (
    <Form
      name="dynamic_form_nest_item"
      className="faqs_form"
      layout="vertical"
      onFinish={editAd?.id ? onUpdateAds : onFinish}
      autoComplete="off"
      form={form}
    >
      <Row
        style={{ display: "flex", marginBottom: 8 }}
        align="baseline"
        justify="space-between"
      >
        <Col span={22}>
          <Form.Item
            name={"text"}
            // fieldKey={[fieldKey, "question"]}
            label=" Text "
            rules={[
              {
                required: true,
                message: "Please enter the text!",
              },
            ]}
          >
            <Input
              // defaultValue={editAd?.text || ""}
              placeholder="Text"
              className="custom-input"
            />
          </Form.Item>
          <Form.Item name="image" label=" Image ">
           
            <SingleImageUpload
              className="user-image"
              onUploadDone={onUploadLogoDone}
              imageUrl={get(imageUser, "url")}
              setImageUser={setUserImage}
            />
          </Form.Item>
          <Form.Item name="video" label=" Video ">
            <SingleVideoUpload
              className="user-image"
              onUploadDone={onUploadVideoDone}
              videoUrl={get(videoUser, "url")}
              setVideoUser={setUserVideo}
            />
          </Form.Item>
          <Form.Item name="isSponsor" label=" isSponsor ">
            <Checkbox
              checked={componentDisabled}
              onChange={(e) => setComponentDisabled(e.target.checked)}
            ></Checkbox>
          </Form.Item>
          <Form.Item name="start_date" label="Start Date "
          >
            <DatePicker
              format={dateFormat}
            />
          </Form.Item>
          <Form.Item name="end_date" label="End Date ">
            <DatePicker
              format={dateFormat}
            />
          </Form.Item>
        </Col>
        <Col>
          <CloseCircleFilled
            style={{ fontSize: "20px" }}
            // onClick={() => remove(name)}
          />
        </Col>
      </Row>
      <Divider />
      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ marginRight: "8px" }}>
          {editAd?.id ? "Update" : "Submit"}
        </Button>
        <Button type="primary" onClick={onClickBack}>
          Back
        </Button>
      </Form.Item>
    </Form>
  );
};

const ViewAds = ({
  onEdit,
  ads,
  loading,
  setLoading,
  fetchAds,
  setUserImage,
}) => {
  const onClickEdit = (itemUuid) => {
    onEdit(itemUuid,true);
  };
  const onClickDelete = (itemUuid) => {
    deleteHospitalAds(itemUuid)
      .then((result) => {
        setLoading(false);
        notification.success({
          message: DELETED_ADS,
        });
        fetchAds();
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <>
    <Spin spinning={loading}>
      {ads && (
        <>
          <h3>Hospital Ads</h3>
          <List
            itemLayout="horizontal"
            dataSource={ads}
            renderItem={(item) => (
              <>
                <List.Item>
                  <List.Item.Meta title="" description={item.text} />
                  <Button
                    type="primary"
                    onClick={() => onClickEdit(item?.uuid)}
                    style={{ marginRight: "8px" }}
                  >
                    Edit
                  </Button>
                  <Button
                    type="danger"
                    onClick={() => onClickDelete(item?.uuid)}
                    icon={<DeleteOutlined />}
                  ></Button>
                </List.Item>
              </>
            )}
          />
          <br />
          <Button type="primary" onClick={onEdit}>
            Create Ads
          </Button>
        </>
      )}
      </Spin>
    </>
  );
};
