import React, { useState, useSelector } from "react";
import { Button, Col, Row, Spin, Form, Input, message,Image } from "antd";
import "./style.scss";
import { contactDetails } from "../../redux/actions/contactUsAction";
import SingleImageUpload from "../../../src/components/mediaUpload/SingleImageUpload";
import get from "lodash/get";
import assign from "lodash/assign";
import { useHistory } from 'react-router-dom';

import { Upload } from 'antd';
import TextArea from "antd/lib/input/TextArea";
const { Dragger } = Upload;
const ContactUsScreen = () => {
  const [loading, setLoading] = useState(false);
  let history = useHistory();
  const [file, setFile] = useState(null);
  const handleUpload = (file) => {
    message.success(`${file.name} uploaded successfully`);
  };
  const handleRemove = () => {
    setFile(null);
  };
  const onFinish = (values) => {
    assign(values, {
      image: get(imageUser, "key"),
      // image_url: get(imageUser, "url"),
    });
    setLoading(true);
    contactDetails(values,file)
      .then((result) => {
        console.log(result);
        setLoading(false);
        message.success("Query Created Successfully");
      history.push('/dashboard');

      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const [imageUser, setUserImage] = useState();
  const onUploadLogoDone = (imageResponse) => {
    setUserImage(imageResponse);
  };
  return (
    <>
      <Row>
        <Col span={12} className="form">
          <h2 className="title2">Contact Us </h2>
          <p className="description">For Any queries</p>
          <Form
            name="basic"
            layout="vertical"
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 16 }}
            autoComplete="off"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  type: "text",
                },
              ]}
            >
              <Input className="custom-input" />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  type: "email",
                },
              ]}
            >
              <Input className="custom-input" />
            </Form.Item>
            <Form.Item
              label="Phone"
              name="phone"
              rules={[
                {
                  required: true,
                  min: 8,
                  max: 11,
                },
              ]}
            >
              <Input type="number" className="custom-input" />
            </Form.Item>
            <Form.Item
              label="Comments"
              name="comments"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <TextArea></TextArea>
            </Form.Item>
            <Form.Item>
            {/* <Upload
      accept=".png,.jpg,.jpeg"
      beforeUpload={handleUpload}
      showUploadList={false}
      action={()=>{}}
      // action = {handleUpload}
    >
      {file ? (
        <div style={{ position: 'relative', width: 200, height: 200 }}>
          <Image src={URL.createObjectURL(file)} alt="Preview" width={200} height={200} />
          <div
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              cursor: 'pointer',
              background: '#fff',
              padding: '4px',
            }}
            onClick={handleRemove}
          >
            Remove
          </div>
        </div>
      ) : (
        <div
          style={{
            width: 200,
            height: 200,
            border: '2px dashed #ccc',
            borderRadius: 8,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <PlusOutlined style={{ fontSize: 48, color: '#aaa' }} />
          <p style={{ marginTop: 16 }}>Click to upload</p>
        </div>
      )}
    </Upload> */}

              <SingleImageUpload
                className="user-image"
                onUploadDone={onUploadLogoDone}
                imageUrl={get(imageUser, "url")}
                setImageUser={setUserImage}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ cursor: "pointer" }}
                loading={loading}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};
export default ContactUsScreen;
