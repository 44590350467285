import appointment_active from '../assets/icons/appointment_active.svg';
import appointment_inactive from '../assets/icons/appointment_inactive.svg';
import hospital_details_active from '../assets/icons/hospital_details_active.svg';
import hospital_details_inactive from '../assets/icons/hospital_details_inactive.svg';
import departments_inactive from '../assets/icons/departments_inactive.svg';
import dashboard_active from '../assets/icons/dashboard_active.svg';
import doctors_inactive from '../assets/icons/doctors_inactive.svg';
import doctors_active from '../assets/icons/doctors_active.svg';
import dashboard_inactive from '../assets/icons/dashboard_inactive.svg';
import hospital_admins_active from '../assets/icons/hospital_admins_active.svg';
import hospital_admins_inactive from '../assets/icons/hospital_admins_inactive.svg';
import notification_bell_active from "../assets/icons/notification_bell_active.svg";
import notification_bell_inactive from "../assets/icons/notification_bell_inactive.svg";
import favourite from "../assets/icons/favourite.svg";
import assistants_inactive from '../assets/icons/assistants_inactive.svg';
import assistants_active from '../assets/icons/assistants_active.svg';
import avaiavility_inactive from '../assets/icons/availability_inactive.svg';
import requests_active from '../assets/icons/requests_active.svg';
import requests_inactive from '../assets/icons/requests_inactive.svg';

import * as Routes from './routes';
import { SUPER_ADMIN_ROLE,ADMIN_ROLE,DOCTOR_ROLE,MANAGER_ROLE,DELEGATE_ROLE,PATIENT_ROLE } from './constantRoles';

const Menu = [
  {
    name: 'Dashboard',
    inactive_icon: dashboard_inactive,
    active_icon: dashboard_active,
    route: Routes.DASHBOARD,
    allowed_roles: [SUPER_ADMIN_ROLE, ADMIN_ROLE, DOCTOR_ROLE, DELEGATE_ROLE],
    key: '0',
  },
  {
    name: 'Appointments',
    inactive_icon: appointment_inactive,
    active_icon: appointment_active,
    route: Routes.APPOINTMENTS,
    allowed_roles: [ADMIN_ROLE, DOCTOR_ROLE, MANAGER_ROLE, DELEGATE_ROLE],
    key: '1',
  },
  {
    name: 'Hospitals',
    inactive_icon: requests_inactive,
    active_icon: requests_active,
    route: Routes.HOSPITALS,
    allowed_roles: [SUPER_ADMIN_ROLE],
    key: '2',
  },
  {
    name: 'Assistants',
    inactive_icon: assistants_inactive,
    active_icon: assistants_active,
    route: Routes.ASSISTANTS,
    allowed_roles: [DOCTOR_ROLE],
    key: '3',
  },
  {
    name: 'Availability',
    inactive_icon: avaiavility_inactive,
    active_icons: '',
    route: Routes.AVAILABILITY,
    allowed_roles: [DOCTOR_ROLE, DELEGATE_ROLE],
    key: '4',
  },
  {
    name: 'Doctors',
    inactive_icon: doctors_inactive,
    active_icon: doctors_active,
    route: Routes.DOCTORS,
    allowed_roles: [ADMIN_ROLE],
    key: '5',
  },

  {
    name: 'Hospital Details',
    inactive_icon: hospital_details_inactive,
    active_icon: hospital_details_active,
    route: Routes.HOSPITAL_DETAILS,
    allowed_roles: [ADMIN_ROLE],
    key: '6',
  },

  {
    name: 'Departments',
    inactive_icon: departments_inactive,
    active_icon: '',
    route: Routes.DEPARTMENTS,
    allowed_roles: [ADMIN_ROLE],
    key: '7',
  },
  {
    name: 'Hospital Admins',
    inactive_icon: hospital_admins_inactive,
    active_icon: hospital_admins_active,
    route: Routes.HOSPITAL_ADMINS,
    allowed_roles: [ADMIN_ROLE],
    key: '8',
  },
  {
    name: 'Settings',
    inactive_icon: hospital_admins_inactive,
    active_icon: hospital_admins_active,
    route: Routes.SETTINGS,
    allowed_roles: [DOCTOR_ROLE],
    key: '9',
  },
  {
    name: 'Cities',
    inactive_icon: hospital_admins_inactive,
    active_icon: hospital_admins_active,
    route: Routes.CITIES,
    allowed_roles: [SUPER_ADMIN_ROLE],
    key: '10',
  },
  {
    name: 'Billings',
    inactive_icon: hospital_admins_inactive,
    active_icon: hospital_admins_active,
    route: Routes.BILLINGS,
    allowed_roles: [SUPER_ADMIN_ROLE],
    key: '11',
  },
  {
    name: 'Contact Us',
    inactive_icon: hospital_details_inactive,
    active_icon: hospital_details_active,
    route: Routes.CONTACT_US,
    allowed_roles: [ADMIN_ROLE],
    key: '12',
  },
  {
    name: 'Ads',
    inactive_icon: dashboard_inactive,
    active_icon: dashboard_active,
    route: Routes.ADS,
    allowed_roles: [ADMIN_ROLE],
    key: '14',
  },
  {
    name: 'Notifications',
    inactive_icon: notification_bell_inactive,
    active_icon: notification_bell_active,
    route: Routes.NOTIFICATIONS,
    allowed_roles: [ADMIN_ROLE],
    key: '15',
  },
  {
    name: 'Questions',
    inactive_icon: requests_inactive,
    active_icon: requests_active,
    route: Routes.QUESTIONS,
    allowed_roles: [ADMIN_ROLE],
    key: '16',
  },
  {
    name: 'Comments',
    inactive_icon: requests_inactive,
    active_icon: requests_active,
    route: Routes.COMMENTS,
    allowed_roles: [SUPER_ADMIN_ROLE],
    key: '13', 
  },
  {
    name: 'Hospital Agents',
    inactive_icon: requests_inactive,
    active_icon: requests_active,
    route: Routes.HOSPITAL_AGENTS,
    allowed_roles: [SUPER_ADMIN_ROLE],
    key: '17', 
  },
  {
    name: 'Questions',
    inactive_icon: requests_inactive,
    active_icon: requests_active,
    route: Routes.DOCTOR_QUESTIONS,
    allowed_roles: [DOCTOR_ROLE],
    key: '18', 
  },
  // {
  //   name: 'Tv Ads',
  //   inactive_icon: dashboard_inactive,
  //   active_icon: dashboard_active,
  //   route: Routes.TV_ADS,
  //   allowed_roles: [ADMIN_ROLE],
  //   key: '19',
  // },
  {
    name: 'Media & Ads',
    inactive_icon: dashboard_inactive,
    active_icon: dashboard_active,
    route: Routes.MEDIA_AND_ADS,
    allowed_roles: [ADMIN_ROLE],
    key: '20',
  },
];

export default Menu;
