import {
    Col,
    Radio,
    Row,
    Space,
    Spin,
    DatePicker,
    Select,
    Input,
    Table,
    Drawer,
    Modal,
    Button,
    Form,
    InputNumber,
    notification
  } from 'antd';
  import { useEffect, useState } from 'react';
  import moment from 'moment';
  import { filterBillings, filterDays } from '../../constants/options';
  import get from 'lodash/get';
  import map from 'lodash/map';
  import {
    getBillings,
    markInvoicePaid,
  } from '../../redux/actions/superAdminAction';
  import { SearchOutlined } from '@ant-design/icons';
  import './styles.scss';
  import DoneModal from '../../components/modals/doneModal/DoneModal';
  import SendInvoice, {InvoiceCard} from '../../components/sendInvoice/SendInvoice';
  import { validateMessages } from '../../constants/validation';
import { MediaAndAdsColumn } from '../../constants/table/mediaAndAdsColumn';
import { getMediaAds,deleteHospitalTv,getMediaTvs } from '../../redux/actions/hospitalAction';
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AddTvDetails from '../../components/drawerContent/addTvDetails/AddTvDetails';
import { DELETED_TV } from "../../constants/messages";



  const { Option } = Select;
  const { Search } = Input;
  
  const { RangePicker } = DatePicker;
  const MediaAndAdsScreen = () => {
    const hospital_uuid = useSelector((state) =>
    get(state, "auth.currentHospital.id")
  );
  const history = useHistory();

  const[currentTvId,setCurrentTvId] = useState();
    const [loading, setLoading] = useState(false);
    const [billingsResult, setBillingsResult] = useState({
      billings: [],
      pagination: null,
    });
    const [filter, setFilter] = useState({
      page: 1,
      name: '',
    });
    const handleDays = (days) => {
      getBillingList(
        get(filter, 'page'),
        get(filter, 'name')
      );
    };
  
    const [billingInfo, setBillingInfo] = useState({
      visible: false,
      data: null,
    });
  
    const toggleBillingInfo = (data = null) => {
      setBillingInfo({
        visible: !get(billingInfo, 'visible'),
        data,
      });
    };

    const toggleViewAds=(currentTvId)=>{
      setCurrentTvId(currentTvId);
      currentTvId && history.push(`/media-ads/${currentTvId}`)
    }
    const deleteTv=(itemUuid)=>{
      deleteHospitalTv(itemUuid)
      .then((result) => {
        setLoading(false);
        notification.success({
          message: DELETED_TV,
        });
        getBillingList(
          get(filter, 'page'),
          get(filter, 'name')
        );
      })
      .catch((error) => {
        setLoading(false);
      });
    }
    const [tvid,setTvid] = useState();
    const toggleEdit=(itemUuid)=>{
      setOpenNewTv(true);
      setTvid(itemUuid);
    }
    const [openNewTv,setOpenNewTv] = useState(false);
    const createNewTv=()=>{
      setOpenNewTv(true);
    }
    const closeOpenTv=()=>{
      setOpenNewTv(false);
      setTvid(null);
    }
  
    const [visibleDone, setVisibleDone] = useState(false);
    const [doneMessage, setDoneMessage] = useState('');
  
    const toggleDone = (message = '') => {
      setVisibleDone(!visibleDone);
      setDoneMessage(message);
    };
  
    const [markPaidModal, setMarkPaidModal] = useState({
      visible: false,
      data: null,
    });
    const toggleMarkPaid = (data = null) => {
      setMarkPaidModal({ visible: !get(markPaidModal, 'visible'), data: data });
    };
  
    const [sendInvoiceModal, setSendInvoiceModal] = useState({
      visible: false,
      data: null
    })
    const toggleSendInvoiceModal = (data = null) => {
      setSendInvoiceModal({ 
        visible: !get(sendInvoiceModal, 'visible'), 
        data: data
      })    
    }
  
    const handlePrint = (data) => {
      // <InvoiceCard data={data} />
    }
  
  
    const handleRange = (dates) => {
      if (dates) {
        getBillingList(
          get(filter, 'page'),
          get(filter, 'name')
        );
      }
    };
  
    const onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
    };
  
    const markPaid = (values) => {
      const invoice_uuid = get(markPaidModal, 'data.id');
      markInvoicePaid(invoice_uuid, values)
        .then((result) => {
          setLoading(false);
          toggleMarkPaid();
          toggleDone('Marked as Paid');
          getBillingList(
            get(filter, 'page'),
            get(filter, 'name')
          );
        })
        .catch((error) => {
          setLoading(false);
        });
    };
  
    const getBillingList = (
      page,
      name = ''
    ) => {
      setLoading(true);
      getMediaTvs(hospital_uuid, page, name).then((result) => {
        setLoading(false);
        setBillingsResult({
          billings: get(result, 'data'),
          pagination: get(result, 'meta.pagination'),
        });
        setFilter({ page,name });
      });
    };
    const onSearch = (value) => {
      getBillingList(
        get(filter, 'page'),
        value
      );
    };
  
    useEffect(() => {
      getBillingList(
        get(filter, 'page'),
        // get(filter, 'fromDate'),
        // get(filter, 'toDate'),
        // get(filter, 'days'),
        // get(filter, 'status'),
        get(filter, 'name')
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const toggleLineUp=(currentTvAds)=>{
      window.open(`${process.env.REACT_APP_UI_HOST}/tv-ads/${currentTvAds?.uuid}`, '_blank');
      // window.open(`https://staging-admin.smartmeet.au/tv-ads/${currentTvAds?.uuid}`, '_blank');
    }
    return (
      <Spin spinning={loading}>
        <div style={{display:"flex",justifyContent:"space-between"}}>
          <div className="screen-title">Media Or TVs</div>
          <div>
          <Button type='primary' onClick={createNewTv}>
           + Add TV
           </Button>
          </div>
        </div>
       
        <Table
          columns={MediaAndAdsColumn({
   deleteTv,
  toggleViewAds,
  toggleEdit,
  toggleLineUp
          })}
          dataSource={get(billingsResult, 'billings')}
          pagination={{
            pageSize: get(billingsResult, 'pagination.per_page', 1),
            current: get(billingsResult, 'pagination.current_page', 1),
            total: get(billingsResult, 'pagination.total', 1),
            onChange: (val) => {
              getBillingList(
                val,
                get(filter, 'status'),
                get(filter, 'name')
              );
            },
  
            position: ['bottomLeft'],
          }}
        />
        <Drawer
          className="main-drawer"
          destroyOnClose
          width="25%"
          closable={false}
          onClose={closeOpenTv}
          visible={openNewTv}
        >
          {/* <InvoiceInfo
            toggleBillingInfo={toggleBillingInfo}
            data={get(billingInfo, 'data')}
          /> */}
          <AddTvDetails
          closeOpenTv={closeOpenTv}
          setOpenNewTv={setOpenNewTv}
          getTvs={getBillingList}
          tvid={tvid}
          setTvId={setTvid}
          />
        </Drawer>
        <DoneModal
          message={doneMessage}
          visible={visibleDone}
          toggleDone={toggleDone}
        />
        <Modal
          className="custom-modal"
          centered={true}
          closable={false}
          destroyOnClose={true}
          visible={get(sendInvoiceModal, 'visible')}
          footer={null}
        >
          <SendInvoice 
            toggleSendInvoiceModal={toggleSendInvoiceModal}
            data={get(sendInvoiceModal, 'data')}
          />
        </Modal>
        <Modal
          className="custom-modal"
          centered={true}
          closable={false}
          destroyOnClose={true}
          visible={get(markPaidModal, 'visible')}
          footer={null}
        >
          <div className="modal-children">
            <div className="title">Mark Invoice as Paid</div>
            <Form
              name="basic"
              layout="vertical"
              onFinish={markPaid}
              onFinishFailed={onFinishFailed}
              validateMessages={validateMessages}
              initialValues={{
                amount: get(markPaidModal, 'data.amount'),
              }}
            >
              <Form.Item
                label="Billing amount"
                name="amount"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <InputNumber
                  addonBefore="$"
                  size="large"
                  min={0}
                  className="custom-input-number"
                />
              </Form.Item>
              <Form.Item label="Payment Reference Id" name="payment_reference_id">
                <Input className="custom-input" />
              </Form.Item>
  
              <Form.Item>
                <Space>
                  <Button type="primary" htmlType="submit">
                    Mark Paid
                  </Button>
                  <Button type="default" onClick={() => toggleMarkPaid()}>
                    Cancel
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </div>
        </Modal>
      </Spin>
    );
  };
  
  export default MediaAndAdsScreen;
  