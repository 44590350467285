export const BASE_URL = process.env.REACT_APP_API_HOST;
export const LOGIN = '/staff/login';
export const SWITCH = '/staff/switch-hospital';
export const INVITE = '/doctors/invite/';
export const LISTDOCTORS = '/doctors?page={page}&limit={limit}';
export const UPDATEDOCTOR = '/doctors';
export const SPECLIST = '/specialisations';
export const SINGLEDOCTOR = '/doctors/';
export const WORKINGHOURS = '/doctors/{doctor_uuid}/working-hours';
export const VALID_DOCTOR_LINK = '/doctors/validate-link/';
export const VALID_HOSPITAL_AGENT_LINK = '/hospital-agent/validate-link/';
export const DOCTOR_SLOTS =
  '/doctors/{doctor_uuid}/appointment-slots/{hospital_uuid}?date={date}'; //2021-07-21
export const DEPARTMENTS = '/departments';
export const GET_DEPT_MANAGERS = '/staffs/managers?department={department_id}';
export const GET_DEPT_MANAGER_BY_ID = '/staffs/manager?user_id={user_id}&department_id={department_id}';
export const INVITE_STAFF = '/staffs/invite';
export const STAFF_DASHBOARD =
  '/staffs/dashboard?start_date={from_date}&end_date={to_date}';
export const DOCTOR_MONTHLY_AVAILABILITY =
  '/doctors/{doctor_uuid}/monthly-availability/{hospital_uuid}?date={date}'; //2021-07-21
export const DOCTOR_LEAVE = 'doctors/{doctor_uuid}/doctors-leaves';
export const DELETE_DOCTOR_LEAVE = 'doctors/{doctor_uuid}/doctors-leaves/{uuid}';
export const FETCH_ADMINS = '/staffs/admins';
export const FETCH_HOSPITALS = '/hospitals';
export const INVITE_HOSPITAL_AGENT = '/hospital-agent/invite';
export const GET_HOSPITAL_AGENTS = "/hospital-agents";
export const REGISTER_HOSPITAL_AGENT = '/hospital-agent';
export const LISTDOCTORSDEPARTMENT =
  '/doctors?page={page}&limit=10&department_uuid={department_uuid}';
export const ADDDOCTORS = '/departments/doctors';
export const VALID_STAFF_LINK = '/staffs/validate-link/';
export const GET_HOSPITAL_AGENT_INVITATION = '/hospital-agent/invitation/{uuid}';
export const REGISTER_STAFF = '/staffs';
export const APPOINTMENTS = '/appointments';
export const APPOINTMENT_NOTES =
  '/appointments/{appointment_uuid}/appointment-note';
export const UPDATE_APPOINTMENT_NOTES =
  '/appointments/{appointment_uuid}/appointment-note/{appointment_note_uuid}';
export const MEDICAL_HISTORY = '/patients/{patient_uuid}/medical-history';
export const UPDATE_APPOINTMENT_STATUS = '/appointments/{appointment_uuid}';
export const HOSPITAL_REQUESTS = '/hospitals';
export const RESCHEDULE_APPOINTMENT =
  '/appointments/{appointment_uuid}/reschedule';
export const IMAGE_UPLOAD = `${BASE_URL}/media`;
export const UPDATE_HOSPITAL_STATUS = '/hospitals/{hospital_uuid}/status';
export const DELEGATES = '/staffs/delegates';
export const HOSPITAL_DETAILS = '/hospitals/{hospital_uuid}';
export const UPDATE_USERS = '/users';
export const GET_DOCTOR_FAQS = 'doctors/{doctor_uuid}/faqs';
export const GET_HOSPITAL_FAQS = 'hospitals/{hospital_uuid}/faqs';
export const POST_HOSPITAL_FAQS = '/hospitals/faqs';
export const POST_DOCTOR_FAQS = '/doctors/faqs';
export const CREATE_CLAIM = '/create-claim';
export const UPDATE_CLAIM = '/claim/{id}';
export const GET_CLAIMS = '/claims';
export const GET_CLAIM_TYPES = '/claimtypes';
export const DOWNLOAD_MEDICAL_HISTORY =
  '/patients/{patient_uuid}/medical-history/{appointment_notes_uuid}/print';
export const CITIES = '/cities';
export const INVOICE_LIST = '/invoices';
export const MARK_PAID = '/invoices/{invoice_uuid}/paid';
export const HOSPITAL_INVOICE =
  '/hospitals/{hospital_uuid}/invoices?limit=10&page={page}';
export const UPDATE_BILLINGS = '/hospitals/{hospital_uuid}/billing-types';
export const FORGOT_PASS = '/forgot-password';
export const VALIDATE_FORGOT_PASS = '/validate-forgot-password/';
export const CHANGE_PASS = '/change-password';
export const POST_CONTACT_US = "/contactus/staff";
export const CONTACTUS_IMAGE_UPLOAD = `${BASE_URL}/contactus/image`;
export const CONTACTUS_VIDEO_UPLOAD = `${BASE_URL}/contactus/video`;
export const COMMENTS ='/contactus';
export const DELETE_COMMENT = '/contactus/{uuid}';
export const GET_COMMENT = '/contactus/{uuid}';
export const GET_DOCTOR_INVITATIONS = 'doctor/invitations';
export const GET_STAFF_INVITATIONS = 'staffs/invitations';
export const DELETE_DOCTOR = "/doctors/{uuid}";
export const DELETE_CITY = "/cities/{uuid}";
export const DELETE_DEPARTMENT = "/departments/{uuid}";
export const DELETE_HOSPITAL_ADMIN = "/staffs/admins/{uuid}";
export const GET_HOSPITAL_ADS = "/hospitals/{hospital_uuid}/ads";
export const POST_HOSPITAL_ADS = '/hospitals/ads';
export const DELETE_HOSPITAL_ADS = '/hospitals/ads/{uuid}';
export const UPDATE_HOSPITAL_ADS = '/hospitals/{uuid}/ads';
export const GET_HOSPITAL_AD = '/hospitals/ads/{uuid}';

export const GET_HOSPITAL_TVADS = "/hospitals/{hospital_uuid}/tvads";
export const POST_HOSPITAL_TVADS = '/hospitals/tvads';
export const DELETE_HOSPITAL_TVADS = '/hospitals/tvads/{uuid}';
export const UPDATE_HOSPITAL_TVADS = '/hospitals/{uuid}/tvads';
export const GET_HOSPITAL_TVAD = '/hospitals/tvads/{uuid}';
export const GET_MEDIA_TVS = '/hospitals/{hospital_uuid}/media-tvs';


export const POST_HOSPITAL_TVS = '/hospitals/tvs';
export const GET_HOSPITAL_TVS = "/hospitals/{hospital_uuid}/tvs";
export const DELETE_HOSPITAL_TV = '/hospitals/tvs/{uuid}';
export const UPDATE_HOSPITAL_TV = '/hospitals/{uuid}/tv';
export const GET_HOSPITAL_TV = '/hospitals/tv/{uuid}';


export const POST_MEDIA_TV = '/hospitals/media-ads';
// export const GET_MEDIA_TVS = "/hospitals/{hospital_uuid}/tvs";
export const DELETE_MEDIA_TV = '/hospitals/media-ads/{uuid}';
export const UPDATE_MEDIA_TV = '/hospitals/{uuid}/media-ad';
export const GET_MEDIA_TV = '/hospitals/media-ad/{uuid}';
export const GET_MEDIA_ADS = '/hospitals/{tv_id}/media-ads';
export const GET_TV_ADS = '/hospitals/{tv_id}/tv-ads';


// export const GET_MEDIA_TVS = '/hospitals/{hospital_uuid}/media-tvs';




export const GET_HOSPITAL_NOTIFICATIONS = "/hospitals/{hospital_uuid}/notifications";
export const POST_HOSPITAL_NOTIFICATIONS = "/hospitals/notifications";
export const DELETE_HOSPITAL_NOTIFICATIONS = '/hospitals/notifications/{uuid}';
export const UPDATE_HOSPITAL_NOTIFICATIONS = '/hospitals/{uuid}/notifications';
export const GET_HOSPITAL_NOTIFICATION = 'hospitals/notification/{uuid}';
export const POST_EMERGENCY_APPOINTMENT = "/appointments/emergency";
export const GET_PATIENT_MEMBERS = '/patients/members/{uuid}';
export const GET_PATIENT_DASHBOARD = "/patients/dashboard/{uuid}";
export const GET_DOCTOR_APPOINTMENT_SLOTS = "/doctors/{uuid}/appointment-slots/{hospital_uuid}?date={date}";
export const GET_HOSPITAL_QUESTIONS = "/hospitals/{hospital_uuid}/questions";
export const POST_HOSPITAL_QUESTIONS = "/hospitals/question";
export const DELETE_HOSPITAL_QUESTION = '/hospitals/question/{uuid}';
export const UPDATE_HOSPITAL_QUESTION = '/hospitals/{uuid}/question';
export const GET_DOCTORS_QUESTIONS = "/doctors/{doctor_uuid}/questions";
export const GET_ALL_PATIENT_ANSWERED_QUESTIONS = "/doctors/{doctor_uuid}/{hospital_uuid}/patient_answered_questions";
export const GET_INCLUDED_DOCTOR_QUESTIONS = '/doctor/{doctor_uuid}/included-questions';
export const GET_NOT_INCLUDED_DOCTOR_QUESTIONS = '/doctor/{doctor_uuid}/not-included-questions';
export const POST_DOCTOR_QUESTION = "/doctor/question";
export const POST_INCLUDED_QUESTIONS = '/doctor/included-questions';
export const DELETE_DOCTOR_QUESTION = '/doctor/question/{uuid}';
export const UPDATE_DOCTOR_QUESTION = '/doctor/{uuid}/question';
export const CHANGE_DEFAULT_LOGIN="/change-default";
export const POST_NOTE_TEMPLATE= "/doctor/note-template";
export const GET_NOTE_TEMPLATE="/doctor/{doctor_user_id}/note-template";
export const FETCH_NOTE_TEMPLATE_BY_ID="/doctor/note-template/{uuid}";


export const UPDATE_DOCTOR_DELAY="/doctor/{uuid}/update-delay";