import {
  Col,
  Radio,
  Row,
  Space,
  Spin,
  DatePicker,
  Select,
  Input,
  Table,
  Drawer,
  Modal,
  Button,
  Form,
  InputNumber,
  notification,
} from "antd";
import get from "lodash/get";
import "./styles.scss";
import Player from "../Player/Player";
import { Grid } from '@mui/material';
import moment from "moment";
import { useEffect, useState } from "react";
import {
  getAllTvAds
} from "../../redux/actions/hospitalAction";
import { useParams } from "react-router";
import { YEAR_MONTH_DAY } from "../../constants/constantsDateFormats";


const { Option } = Select;
const { Search } = Input;


const { RangePicker } = DatePicker;
const CurrentTvAdsScreen = () => {
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const [billingsResult, setBillingsResult] = useState({
    billings: [],
    pagination: null,
  });


  const [filter, setFilter] = useState({
    page: 1,
    fromDate: moment().format(YEAR_MONTH_DAY),
    toDate: moment().format(YEAR_MONTH_DAY),
    days: 1,
    status: null,
    name: "",
  });
  
  const getBillingList = (
    page,
    fromDate,
    toDate,
    days,
    status = null,
    name = ""
  ) => {
    setLoading(true);
    getAllTvAds(params?.currentTvId, fromDate, toDate, status, page, name).then(
      (result) => {
        setLoading(false);
        setBillingsResult({
          billings: get(result, "data"),
          pagination: get(result, "meta.pagination"),
        });
        setFilter({ page, fromDate, toDate, days, status, name });
      }
    );
  };

  useEffect(() => {
    getBillingList(
      get(filter, "page"),
      get(filter, "fromDate"),
      get(filter, "toDate"),
      get(filter, "days"),
      get(filter, "status"),
      get(filter, "name")
    );
  }, []);

const extractedData = billingsResult?.billings?.length > 0 && billingsResult?.billings?.map(item => {
  return {
    videoLink: item.video_url,
    priority: item.play_order
  };
});

  return (
    <Grid container alignContent="center" justifyContent="center" spacing={2} height="100vh">
      <Grid item xs={5}>
        <Player
        videos = {extractedData?.length>0 ? extractedData : [] }
          url={
            'https://res.cloudinary.com/oneplayer/video/upload/v1658029376/highquality_videos/Vikram_Hitlist___Rolex_Sir_Promo___Kamal_Haasan___Suriya___Vijay_Sethupathi___Lokesh_Kanagaraj_ott5jd.mp4'
          }
          light={
            'https://images.unsplash.com/photo-1655601597743-7ddd6fdc2903?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=700&q=80'
          }

        />
      </Grid>
    </Grid>
  );
};

export default CurrentTvAdsScreen;
